<template>
  <b-modal
    id="modalAddTraineeUser"
    :visible="modalVisibility(modalId)"
    title="Register New Trainee"
    @show="onShow()"
    @hidden="resetModal()"
    @ok="onOk"
    size="md"
    no-close-on-backdrop
    ok-title="Submit"
  >
    <div>
      <b-row>
        <b-col>
          <base-input
            v-model="form.trainee_fullname"
            label="Full Name"
            :validator="$v.form.trainee_fullname"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            v-model="form.trainee_username"
            label="Username"
            :validator="$v.form.trainee_username"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            v-model="form.trainee_email"
            label="Email"
            :validator="$v.form.trainee_email"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            v-model="form.trainee_ic_number"
            label="IC Number"
            :validator="$v.form.trainee_ic_number"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            v-model="form.trainee_phone_number"
            label="Phone Number"
            :validator="$v.form.trainee_phone_number"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-password
            v-model="form.trainee_password"
            label="Password"
            :validator="$v.form.trainee_password"
            :messages="localMessages"
          ></base-password>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-password
            v-model="form.trainee_password_confirmation"
            label="Confirm Password"
            :validator="$v.form.trainee_password_confirmation"
            :messages="localMessages"
          ></base-password>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
import { email, required, sameAs } from "vuelidate/lib/validators";
import {
  CREATE_TEMP_TRAINEE_USER_REGISTRATION,
  GET_TRAINEE_USER_REG_PENDING_LIST,
} from "@/core/services/store/modules/clientadmin/trainee.module";
import SystemHelper from "@/core/services/systemhelper.service";

export default {
  mixins: [validationMixin],
  components: {},
  data() {
    return {
      modalId: "modalAddTraineeUser",
      isBusy: true,
      form: {
        trainee_fullname: "",
        trainee_username: "",
        trainee_email: "",
        trainee_ic_number: "",
        trainee_phone_number: "",
        trainee_password: "",
        trainee_password_confirmation: "",
      },
    };
  },
  validations: {
    form: {
      trainee_fullname: { required },
      trainee_username: { required },
      trainee_email: { required, email },
      trainee_phone_number: { required },
      trainee_ic_number: { required },
      trainee_password: { required },
      trainee_password_confirmation: {
        sameAsPassword: sameAs("trainee_password"),
      },
    },
  },
  computed: {},
  methods: {
    onShow() {
      console.log("showing");
    },
    onOk(bvModalEvent) {
      console.log(bvModalEvent);
      bvModalEvent.preventDefault();

      // trigger submit
      this.onSubmit();
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      } else {
        console.log("submit");

        let payload = this.form;
        SystemHelper.confirmationDialogHandler(
          { title: "Confirmation", html: "Add User?" },
          { action: CREATE_TEMP_TRAINEE_USER_REGISTRATION, payload: payload },
          [
            {
              action: GET_TRAINEE_USER_REG_PENDING_LIST,
              payload: this.defaultPayload,
            },
          ],
          "modalAddTraineeUser"
        );
      }
    },
    resetModal() {
      this.$v.form.$reset();
      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>
